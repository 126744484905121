<template>
    <slot
        v-if="youTubeConsent"
        v-bind="$attrs"
    ></slot>
    <template v-else>
        <button
            class="button"
            v-bind="$attrs"
            @click="getConsent"
        >
            YouTube consent required...
        </button>
    </template>
</template>

<script
    setup
    lang="ts"
>
/** A "gate" component that prevents usage of the slotted (YouTube) component without consent.
 *  Uses the {YouTubeConsentDialog} to aks for consent from the user
 */
import { consentYouTube } from '@/code/ui/dialogs';
import { useSettingsStore } from './../../store/settings';
import { storeToRefs } from 'pinia';

const settings = useSettingsStore();
const { youTubeConsent } = storeToRefs(settings);

function getConsent() {
    consentYouTube().then((ok) => {
        if (ok) {
            youTubeConsent.value = true;
        }
    });
}
</script>
